import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import HeaderBar from './shared/HeaderBar';
import RenameFiles from './subcomponents/RenameFiles';
import ResultsTable from './subcomponents/ResultsTable';
import ResultsCharts from './subcomponents/ResultsCharts';
import CircularCountdownTimer from './shared/CircularCountdownTimer';
import { Container, Row, Col } from 'react-bootstrap';
import { useSession } from '../contexts/SessionContexts';
import CircularProgressWithLabel from './shared/CircularProgressWithLabel';
import HeaderWithClipboard from './shared/HeaderWithClipboard';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Tooltip, ToggleButton, ToggleButtonGroup} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';
import API_BASE_URL from '../config';
import * as XLSX from 'xlsx';
import Backdrop from '@mui/material/Backdrop';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import InformationTooltip from './shared/InformationTooltip';
import Typography from '@mui/material/Typography';
import ReactGA from 'react-ga';

import { BarChart } from '@mui/x-charts/BarChart';

import { useParams } from "react-router-dom";
//import { useUserStandard } from '../contexts/InitiateContexts';

const Results = () => {
  const [userStandard, setUserStandard] = useState(null);
  const { userId: userId, sessionId: sessionGuid } = useParams();
  
  const [view, setView] = useState('table');
  const [rawResultsData, setRawResultsData] = useState(null);
  const [processedResultsData, setProcessedResultsData] = useState([]);
  const [tableData, setTableData] = useState([]);
  
  // Session statistics
  const [fileCount, setFileCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(true);
  const [queuePosition, setQueuePosition] = useState('N/A');
  const [statusCounts, setStatusCounts] = useState({
    Pass: 0,
    Warning: 0,
    Error: 0,
    Fail: 0
  });
  const [isProcessed, setIsProcessed] = useState(false);

  const [fetchResultsInProgress, setFetchResultsInProgress] = useState(false)
  const [countdown, setCountdown] = useState(2); // Initial countdown value in seconds
  const [showSpinner, setShowSpinner] = useState(false)

  const [showTags, setShowTags] = useState(false);
  const [showSearchTerms, setShowSearchTerms] = useState(false);
  const [showRenameSettings, setShowRenameSettings] = useState(false);
  const [modifiedFileNames, setModifiedFileNames] = useState({});
  const [renamedFiles, setRenamedFiles] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [editedRow, setEditedRow] = useState(null); // State to store the edited row

  // Google analytics ping on component mount
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // fetch results gets results from the blob, and parses them into an object that is returned and is used to manage the processedResultsData state
  // a second state, tableData is used to populate the table. This is so that the user can make edits to results before the process has completed
  // without tableData (which is only updated to receive new result rows), every time a new result json is received to the frontend all table data would be overwritten
  const fetchResults = async () => {

    setFetchResultsInProgress(true);
    setShowSpinner(true);

    try {
        console.log('Fetching results...');
        const response = await axios.get(`${API_BASE_URL}/api/GetResultsTrigger?sessionGuid=${sessionGuid}&folderName=${userId}`);
        console.log('Results data received:', response.data);

        const resultsKeys = Object.keys(response.data.results);
        if (resultsKeys.length === 0) {

          setIsProcessing(false)

          const response = await axios.get(`${API_BASE_URL}/api/GetQueuePosition?sessionGuid=${sessionGuid}&folderName=${userId}`);
          
          setQueuePosition(response.data);
          console.log('Hello?')
          console.log(queuePosition);
          console.log(response);
          return
        }
        
        const rawResultsData = response.data.results;
        console.log('Results data:', rawResultsData);
        
        setRawResultsData(rawResultsData);
        setFileCount(response.data.status.session.file_count);

      const counts = {
        Pass: 0,
        Warning: 0,
        Error: 0,
        Fail: 0
      };

      // Loop through the files and count statuses
      for (const status of Object.values(response.data.status.files)) {
        if (status === 'Pass') {
          counts.Pass++;
        } else if (status === 'Warning') {
          counts.Warning++;
        } else if (status === 'Error') {
          counts.Error++;
        } else if (status === 'Fail') {
          counts.Fail++;
        }
      }

      // Update state with counts
      setStatusCounts(counts);

      // Check if the number of files processed is equal to the total number of files
      if (response.data.status.session.file_count === Object.keys(response.data.status.files).length) {
        setIsProcessing(false);
        setIsProcessed(true); // Set isProcessed to true if all files are processed
}
        //setProcessedFileCount(response.data.status.processed_file_count);
       
  
        // Populate processedResults state with fetched results
        const resultKeys = Object.keys(rawResultsData);
        const processedResultsData = resultKeys.map((resultKey) => {
            const result = rawResultsData[resultKey];
            const fileName = modifiedFileNames[resultKey] || resultKey.split('/')[1].split('_results.json')[0];
            const description = result.description;
            const classifiers = result.classifiers || [];
            const extractions = result.extractions || {};
            const tags = extractions.tags || [];
            // Check if extractions.searchTerms exists before accessing its properties
            const searchTerms = extractions && extractions.searchTerms ? extractions.searchTerms : [];

// Initialize searchTermObject outside of the loop
const searchTermObject = {};

// Helper function to flatten nested structures recursively
const flattenNestedValue = (value) => {
  if (Array.isArray(value)) {
    // Flatten arrays
    return value.map((item) => flattenNestedValue(item)).join('; ');
  } else if (typeof value === 'object' && value !== null) {
    // Flatten nested objects
    const flattenedObject = [];
    for (const [key, val] of Object.entries(value)) {
      flattenedObject.push(`${key}: ${flattenNestedValue(val)}`);
    }
    return flattenedObject.join('; ');
  } else {
    // Direct values
    return value;
  }
};

// Iterate over each searchTerm object
searchTerms.forEach((searchTermObj) => {
  let searchTerm, value;

  // Check if the searchTermObj has the "searchTerm" property directly
  if ("searchTerm" in searchTermObj && "value" in searchTermObj) {
      searchTerm = searchTermObj.searchTerm;
      value = searchTermObj.value;
  } else {
      // If the searchTermObj does not have "searchTerm" property directly, assume it's the searchTerm and value directly
      const entries = Object.entries(searchTermObj);
      if (entries.length === 1) {
          [searchTerm, value] = entries[0]; // Extract searchTerm and value
      } else {
          console.error("Invalid search term object:", searchTermObj);
          return; // Skip invalid search term object
      }
  }

  // Process and store searchTerm and value
  const flattenedValue = flattenNestedValue(value);

  // Check if the documentName already exists as a key in searchTermObject
  if (!searchTermObject[fileName]) {
      // If not, initialize it as an empty object
      searchTermObject[fileName] = {};
  }

  // Assign the flattened value to the corresponding documentName and searchTerm
  searchTermObject[fileName][searchTerm] = flattenedValue;

  console.log('searchTermObject:', searchTermObject); // Log searchTermObject here
});





// Map over each classifiers using the code part ID (e.g. IS01) to extract nested results 'data' for each code
// Remaining code...
            // Map over each classifiers using the code part ID (e.g. IS01) to extract nested results 'data' for each code ('data' is a specific object in the results.json)
            // IMPORTANT the extracted data (code, certainty etc.) are returned as an array, which is nested within the processedResultsData object
            // this is because the table component cannot handle objects, only arrays
            // in order to display multiple data points in a single cell it is necessary to pass the cell an array, and then use positional indexing to render the relevant data
            const classifierResults = classifierID.map((id) => {
              const relevantClassifier = classifiers.find(classifier => classifier.id === id);
              const code = relevantClassifier?.data[0]?.code || '';
              const certainty = relevantClassifier?.data[0]?.certainty || '';
              const explanation = relevantClassifier?.data[0]?.explanation || '';
              //console.log(explanation)
              
              // Determine the emoji based on certainty
              // The emoji is passed along with the certainty string, to provide a more visual way of the user identifying erroneous results
              let emoji = '';
              switch (certainty) {
                  case 'low':
                      emoji = ' ⚠️';
                      break;
                  case 'medium':
                      emoji = <span style={{ fontSize:"18px", color: '#FFAA33' }}><b>&nbsp; ✓ </b></span>
                      break;
                  case 'high':
                      emoji = <span style={{ fontSize:"18px", color: '#03C03C' }}><b>&nbsp; ✓</b></span>; // High certainty, tick emoji
                      break;
                  case 'edited':
                      emoji = ' user edited'
                  default:
                      emoji = ''; // Default case, no emoji
                      break;
              }
              // classifier data returned as an array
              return [ code, certainty, explanation, emoji ];
          });

            // Map search terms to their corresponding values
    const searchTermValues = searchTermNames.map((searchTermName) => {
        const relevantSearchTerm = searchTerms.find(searchTerm => searchTerm.searchTerm === searchTermName);
        return relevantSearchTerm?.value || '';
    });

    // Find corresponding searchTermObject for the current document
const correspondingSearchTermKey = Object.keys(searchTermObject)[0];
const correspondingSearchTermData = searchTermObject[correspondingSearchTermKey];
console.log(correspondingSearchTermKey)
console.log(correspondingSearchTermData)

// Merge searchTermObject with other data
const mergedData = {
    name: fileName,
    description: description,
    ...Object.fromEntries(
        classifierNames.map((id, index) => [
            id,
            classifierResults[index] || ''
        ])
    ),
    tags: tags.join(', '),
    ...correspondingSearchTermData // Merge searchTermObject data unconditionally
};

return mergedData;
});

console.log('All processed results data:', processedResultsData);


        // Set processedResultsData state to ensure there is a master state containing results as they come back from the backend
        // As new results come in, this table is overwritten
        setProcessedResultsData(processedResultsData);

        // Set tableData state mimics processedResultsTable and exists purely to allow the user to edit while the process hasn't yet completed
        // As new data comes into processedResultsData a check is performed to filter out any files that already exist in tableData
        // New result rows are then added to tableData with existing rows untouched, when the user edits the table component it updates the tableData state
        setTableData(prevTableData => {
          // Filter out items from processedResultsData that already exist in prevTableData
          const newData = processedResultsData.filter(newItem => !prevTableData || !prevTableData.some(existingItem => existingItem.name === newItem.name));
        
          // Map over newData to prepare the new data for insertion
          const modifiedData = newData.map(newItem => {
            // Create a copy of the newItem
            const modifiedItem = { ...newItem };
            // Loop through each classifier and keep only the first item
            Object.keys(modifiedItem).forEach(key => {
              if (Array.isArray(modifiedItem[key])) {
                modifiedItem[key] = modifiedItem[key][0]; // Keep only the first item
              }
            });
            return modifiedItem;
          });

          console.log("TABLE DATA", tableData)
        
          // Concatenate the modifiedData with prevTableData to update the table data
          return prevTableData ? [...prevTableData, ...modifiedData] : modifiedData;
        });
        
    } catch (error) {
        console.error('Error fetching results:', error);
    } finally {
        setShowSpinner(false);
        setFetchResultsInProgress(false)
        setCountdown(60);
    }
};

  // Logic to run get and set results from blob every 30 seconds
  // IMPORTANT the initial countdown state is set to 2 seconds, this is just enough time for users who are recovering sessions with existing results to get and load the results
  useEffect(() => {    
    
    // Checks if page has loaded for the first time. 
    if (countdown === 0) {
      fetchResults();
      // Sets length of countdown
      setCountdown(60)
    }

    //console.log('Counting down from', countdown)

    const intervalId = setInterval(() => {
      if (!isProcessed && isProcessing && !fetchResultsInProgress) {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            console.log('Fetching results')
            fetchResults();
            return 60; // Reset countdown
          }
          return prevCountdown - 1;
        });
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(intervalId)
    };
  }, [countdown]);

  // At the same time as the results are being retrieved, the user standard is retrieved from the blob store and set to state
  // It is then parsed and the component parts (classifiers, searchTerms etc) are extracted into their own objects
  // The user standard is used to setup the column headers in the table (i.e. the code part names) and pick lists so users can modify the code part picked by the algorithm
  useEffect(() => {
    // Fetch userStandard data from the backend
    const fetchUserStandard = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/ConfigureResultsTableTrigger?sessionGuid=${sessionGuid}&folderName=${userId}`);
        setUserStandard(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching userStandard:", error);
      }
    };

    fetchUserStandard(); // Call the function when the component mounts
  }, []);

  // Take user standard json and extract classifiers and search terms
  const classifiers = userStandard?.classifiers || [];
  const searchTerms = userStandard?.enrichers.searchTerms || [];
  const tags = userStandard?.enrichers.tags || [];

  // Get classifier names for table headers - these are values that are not needed to be rendered in row / in specific cells
  const classifierID = classifiers.map((classifier) => classifier.id);
  const classifierNames = classifiers.map((classifier) => classifier.name);

  // Get search term names for table headers
  const searchTermNames = searchTerms.map((searchTerm) => searchTerm.searchTerm);

  // Define column names to be passed to material-react-table component
  // Note, I tried putting this into the table component for simplicity, with classifiers passed as props but it created and infinite loop and I couldn't work out why
  const columns = useMemo(() => {

    // Columns are generated dynamically from the fields in the 'data' prop passed to the table component (data=tableData)
    // the material react table component takes the data prop and uses the 'accessorKey' to map data to columns
    const dynamicColumns = [
      // This first column is simple, it looks for 'name' in the data prop, which corresponds to file name
      {
        accessorKey: "name",
        header: "File Name",
        //muiTableHeadCellProps: { sx: { color: "green" } }
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
      },
    ];

    // Conditionally add the newFileName column if it exists in the tableData
  if (tableData && tableData.length > 0 && tableData[0].hasOwnProperty('newFileName')) {
    dynamicColumns.push({
      accessorKey: "newFileName",
      header: "Coded File Name",
      Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
    });
  }
    
    // The rest of the columns are more complicated; the classifier names are extracted from the 'User Standard' and iterated over
    // The classifier name is then passed as the accessorKey to get the values for that code
    classifierNames.forEach(classifierName => {
      // Get the results data associated with each code part which needs to be unpacked positionally (code, certainty, description, emoji)
      const classifierData = userStandard.classifiers.find(classifier => classifier.name === classifierName);

      dynamicColumns.push({
        accessorKey: classifierName,
        header: classifierName,
        filterSelectOptions: (classifierData && classifierData.data) ? 
        classifierData.data.map(item => ({ value: item.code, label: `${item.code} - ${item.description}` })) : [],
        filterVariant: 'select',
        editVariant: 'select',
        // This line generates the list of pick list for each code part if the user wishes to change the result value
        editSelectOptions: (classifierData && classifierData.data) ? classifierData.data.map(item => ({ value: item.code, label: `${item.code} - ${item.description}` })) : [],
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: 'text',
          required: true,
          //store edited ROW in state to be saved later
          onBlur: (event) => {
            const updatedRow = editedRow && editedRow[row.id]
              ? { ...editedRow[row.id], [classifierName]: event.target.value }
              : { ...row.original, [classifierName]: event.target.value };
          
            // Update the editedRow state with the updated row
            setEditedRow((prevEditedRow) => ({
              ...prevEditedRow,
              [row.id]: updatedRow,
            }));
          
            console.log(classifierName);
            console.log(updatedRow);
          
            // Blur the input element if Enter key is pressed - TO BE FIXED
            if (event.key === 'Enter') {
              event.target.blur();
            }
          },
          
          
          
        }),
        // The value passed to cell is accessed automatically by material react table, it uses the accessorKey (in this case the classifierName) and returns the value
        // in this case 'cell' equals an array which needs to be unpacked positionally (code, certainty, explanation, emoji)
        Cell: ({ cell, row }) => {
          if (!classifierData) {
            return null; // Return null if classifierData is not found, i.e. if there are no classifier names
          }
          
          const fileName = row.original.name;
          const cellValue = cell.getValue();

          // Find the corresponding selectedCodeResults for the current filename
          const selectedFileResults = processedResultsData.find(item => item.name === fileName);

        // Check if selectedCodeResults is found
        if (!selectedFileResults || !selectedFileResults[classifierName]) {
          return null; // Return null if selectedCodeResults or classifier data is not found for the current filename
        }

          // In order to display a tooltip of the description (which is drawn from the user standard), we create a variable that uses the code part from the result as the key to access its properties from the user standard
          const selectedCodeInformation = classifierData.data.find(item => item.code === cell.getValue());

          // In order to display info from the results file (certainty and explanation) they need to be mapped to the code part
          const selectedCodeResults = processedResultsData.find(item => item.name === fileName)[classifierName];

          //console.log("These are the selected code results: ", selectedCodeResults[classifierName])

          const selectedCodeEmoji = selectedCodeResults[3];
          const selectedCodeExplanation = selectedCodeResults[2];

          const isEdited = cellValue !== selectedCodeResults[0];
          //console.log(cellValue)
          //console.log("Cell is edited?: ", isEdited)

      
          return (
            <div>
              <Tooltip title={selectedCodeInformation ? selectedCodeInformation.description : ''} arrow>
                <span>
                  <span>{cell.getValue()}</span> {/* Display code */}
                </span>
              </Tooltip>
           
                {/* Check if the cell value matches the classifier code */}
      {isEdited ? (
        // Render "User edited" message and apply styles
        <span style={{ fontStyle: 'italic', color: 'blue' }}>edited</span>
      ) : (
        // Render emoji with tooltip
        <Tooltip title={selectedCodeExplanation} arrow>
          <span>{selectedCodeEmoji}</span>
        </Tooltip>
      )}
            
            </div>
          );
          
        },
      });
    });

    const TagsCell = ({ tags }) => {
      // Split the tags string into an array using comma as the separator
      const tagArray = tags.split(',');
    
      return (
        <div>
          {tagArray.map((tag, index) => (
            <Chip key={index} label={tag.trim()} style={{ marginRight: '5px', marginBottom: '5px' }} />
          ))}
        </div>
      );
    };

    // Add column for tags
if (showTags) {
  dynamicColumns.push({
    accessorKey: "tags",
    header: "Tags",
    Cell: ({ cell, row }) => {
      const fileName = row.original.name;
      const selectedFileResults = processedResultsData.find(item => item.name === fileName);
      const tags = selectedFileResults ? selectedFileResults.tags : [];
      
      return tags.length > 0 ? <TagsCell tags={tags} /> : null;
    }
  });
}

// Add columns for search terms
if (showSearchTerms) {
  searchTermNames.forEach(searchTermName => {
    dynamicColumns.push({
      accessorKey: searchTermName,
      header: <span style={{ color: 'blue' }}>{searchTermName}</span>,
      Cell: ({ cell, row }) => {
        const fileName = row.original.name;
        const selectedFileResults = processedResultsData.find(item => item.name === fileName);
        const searchResult = selectedFileResults ? selectedFileResults[searchTermName] : [];
        //console.log("This is the processed results to pick from: ", processedResultsData)
        //console.log("Truthy or not, this is the test: ", searchResult)

        return (
          <div>
            {searchResult && 
              <div>
                <span>{cell.getValue()}</span>
              </div>
            }
          </div>
        );
      }
    });
  });
}
  
    return dynamicColumns;
  }, [classifierNames, showTags, showSearchTerms, searchTermNames, processedResultsData]);
  

  const handleToggleTags = () => {
    setShowTags(!showTags);
  };

  const handleToggleSearchTerms = () => {
    setShowSearchTerms(!showSearchTerms);
  };

  // Function to save user edits to code parts and update tableData state to ensure the changes persist
  const handleTableDataChange = (updatedData) => {
    setTableData((prevTableData) => {
      // Map over the existing tableData
      const mergedData = prevTableData.map((row) => {
        // Find the edited row in the updatedData using the name property
        const editedRow = updatedData.find((updatedRow) => updatedRow.name === row.name);
        // If the edited row exists, merge it with the existing row, otherwise, return the existing row unchanged
        return editedRow ? { ...row, ...editedRow } : row;
      });
  
      return mergedData;
    });
  };
  
  // MODAL FOR THE USER TO SET THEIR NAMING LOGIC, AND THEN THE SETTINGS ARE PASSED BACK TO THIS COMPONENT WHERE THE FILES ARE RENAMED.
// THE USER CAN UNDO THE ACTION TO REVERT TO THE ORIGINAL FILE NAMES
// WHEN THE USER DOWNLOADS TO EXCEL, WHICHEVER NAMES ARE SHOWING IN THE WEB TABLE ARE USED
// THE DOWNLOAD CALL WILL BE MADE SEPARATELY


// Open the modal
const handleOpenRenameSettingsModal = () => {
  setShowRenameSettings(true);
}

// Close the modal
const handleCloseRenameSettingsModal = () => {
  setShowRenameSettings(false);
}

// Generate a new file name based on the document codes and download settings
const generateFileName = (documentCodes, separator) => {
  return documentCodes.join(separator);
}

const handleUndoRename = () => {
  setModifiedFileNames({}); // Reset modified names
  setRenamedFiles(false)
};

const handleRenameFiles = (codeSettings) => {
  console.log("Rename taking place with following settings: ", codeSettings);
  console.log("Current table data used for rename: ", tableData);
  console.log("List of file names: ", tableData.map(file => file.name));
  console.log("List of classifier names: ", classifierNames);

  // Initialize an object to store the new file names
  const newFileNames = {};

  // Initialize an object to store the sequential numbers for each file name pattern
  const sequentialNumbers = {};

  // Iterate over each file in tableData
  const updatedTableData = tableData.map((file, index) => {
    // Extract classifier codes for the current file
    const classifierCodes = classifierNames.map(classifierName => {
      const classifier = file[classifierName];
      return classifier ? classifier || '' : ''; // If classifier is found, extract code part, otherwise return an empty string
    });

    // Join classifier codes into a single string using a separator
    const separator = codeSettings.documentSeparator;
    const newFileName = classifierCodes.join(separator);

    // Check if the new file name pattern already exists in the sequentialNumbers object
    if (!sequentialNumbers[newFileName]) {
      // Initialize the sequential number for this pattern to 0
      sequentialNumbers[newFileName] = 0;
    }

    // Increment the sequential number for this pattern
    sequentialNumbers[newFileName]++;

    // Generate the sequential number string with leading zeros based on the specified number of digits
    const sequentialNumberString = sequentialNumbers[newFileName].toString().padStart(codeSettings.numberingSystem.digits, '0');

    // Combine the new file name pattern and the sequential number
    const numberedFileName = `${newFileName}${codeSettings.documentSeparator}${sequentialNumberString}`;
    
    // Add the original file name and the corresponding new file name to the newFileNames object
    newFileNames[file.name] = numberedFileName;

    // Add the new file name to the file object
    const updatedFile = { ...file, newFileName: numberedFileName };

    return updatedFile;
  });

  // Log the updated tableData
  console.log("Updated table data with new file names: ", updatedTableData);

  // Update the tableData state with the new file names
  setTableData(updatedTableData);

  // Update the modifiedFileNames state with the new file names
  setModifiedFileNames(newFileNames);
  console.log(modifiedFileNames);
};

// DOWNLOAD FILES AS ZIPPED FOLDER, TAKING WHICHEVER NAME IS PRESENT IN THE WEB TABLE
// DOCUMENT REGISTER IS ALSO DOWNLOADED AT SAME TIME

const handleDownloadFileFromBlob = async () => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/DownloadDocumentsTrigger?sessionGuid=${sessionGuid}`,
      {
        folderName: userId, modifiedFileNames: modifiedFileNames,
      },
      { responseType: 'arraybuffer' }
    );

    // Check if the response is successful
    if (response.status === 200) {
      // Create a blob containing the files
      const blob = new Blob([response.data], { type: 'application/zip' });

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'downloadedFiles.zip';

      // Trigger the download
      link.click();

      // Remove the link element only if it's a child of the document body
      if (document.body.contains(link)) {
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }

    } else {
      console.error('Failed to fetch files from the blob.');
    }
  } catch (error) {
    console.error('Error downloading files:', error);
  }
};

const handleChange = (event, newView) => {
  if (newView !== null) { // Ensure newView is not null
    setView(newView); // Update the view state
  }
};

  // RESULTS TABLE IS BUILT UP BY USING userStandard CONTEXT TO SETUP THE COLUMN HEADERS, THEN RESULT DATA IS MAPPED INTO THE TABLE.
  // THIS MEANS THE RESULT TABLE IS DYNAMICALLY GENERATED BY COMBINING USER STANDARD WITH EACH RESULT JSON
  const renderResultsTable = () => {
    if (!tableData) {
      return (
        <div className="dashed-box">
          <p className='mt-2'>We are processing your results. This table will auto-update, do not refresh the page as it will return you to the homepage.</p>
          <p>Take a copy of your session key so you can retrieve your results later if you don't wish to wait around.</p>
        </div>
      );
    }
  
    return (
      <div>
        <div className={`p-0 ${view === 'table' ? 'visible' : 'hidden'}`}>
          {tableData && view==='table' && <ResultsTable
                        columns={columns}
                        data={tableData}
                        setTableData={setTableData}
                        editedRow={editedRow}
                        setEditedRow={setEditedRow}
                        onSaveRow={handleTableDataChange}
                        onRenameFiles={handleOpenRenameSettingsModal}
                        showTags={showTags}
                        showSearchTerms={showSearchTerms}
                        onToggleTags={handleToggleTags}
                        onToggleSearchTerms={handleToggleSearchTerms}
                        tags={tags}
                        searchTermNames={searchTermNames}
                        onDownloadFiles={handleDownloadFileFromBlob}
                        isModifiedName={modifiedFileNames}
                        userStandard={userStandard}
                        //tableData={tableData}
                        //setTableData={setTableData}
                      />}
        </div>
        <div>
          {tableData && view==='chart' && (
        <Container className={`rounded-box-small ${view === 'chart' ? 'visible' : 'hidden'}`}>TEST
          <ResultsCharts 
                        data={tableData}/>
        </Container>
        )}
        </div>
      </div>
    );
  };

  const renderStatusTitle = () => {
    if (!isProcessed && !isProcessing) {
      return (
        <div className='align-items-center d-flex'> 
          Session queued
          <InformationTooltip title={
            <React.Fragment>
            <Typography color="inherit">What does this mean?</Typography>
            <p>{"Right now we can only analyse a small number of files concurrently."}</p> <p>{"Your position is N/A if there are > 32 sessions ahead in the queue or if we're just about to process your files."}</p><p>{"Keep checking back to see how things are going."}</p>
          </React.Fragment>
          } 
          />
        </div>
      )
    }

    if (isProcessing) {
      return 'Analysing files'
    }

    return 'Analysis complete'
  }

  const renderStatusBody = () => {
    if (!isProcessed && !isProcessing) {
      return (
      <div className='m-0'>
        Queue position is {queuePosition}. &nbsp;
        <button className='btn btn-link m-0 p-0 blue-font' onClick={fetchResults}>
          Refresh status
        </button> 
      </div>
      )
    }

    if (isProcessing) {
      return (
      <div className='m-0'>
        This page will automatically refresh in {countdown} seconds.&nbsp;
        <button className='btn btn-link m-0 p-0 blue-font' onClick={fetchResults}>
          Refresh now
        </button>
      </div>
      )
    }

    return (
      <div className='m-0'>
        {fileCount} file(s) analysed.
      </div>
    )
  }

  const renderStatusIcon = () => {
    if (!isProcessed && !isProcessing) {
      return <TimerOutlinedIcon fontSize='large' style={{color:'white'}}/>
    }

    if (isProcessing) {
      return <AutorenewOutlinedIcon fontSize='large' style={{color:'white'}}/>
    }

    return <DoneRoundedIcon fontSize='large' style={{color:'white'}}/>

  }


  return (
    <div className="secondary-background">
      <HeaderBar homeLink="/" homeText="hoppa" />
      <div className="full-width-primary-section">
      <Container className='mt-2 rounded-box' style={{height: '120px', minHeight: '100px'}}>
          <Row style={{height:'80px'}}>
          <Col xs={4} className='d-flex align-items-center' style={{height:'80px'}}>
          <Col xs={3}>
          <div className='d-flex align-items-center justify-content-center'>
            <div className='icon-wrapper me-3'>
              <div className='icon-outercontainer p-2' style={{backgroundColor: isProcessed? 'green' : '#0051B6'}}>
                {renderStatusIcon()}
              </div>
            </div>
            </div>
          </Col>
          <Col>
          <h5><b>{renderStatusTitle()}</b></h5>
          {renderStatusBody()}
          </Col>
          </Col>
          <Col className='pe-0'>
          <BarChart 
          layout="horizontal"
          colors={['#0B0A07','#06D6A0', '#FFD166', '#EF476F', '#CCD6EB']}
          leftAxis={null}
          bottomAxis={null}
          tooltip={{ trigger: 'item' }}
          height={120}
          slotProps={{
            bar: {
              rx: 10,
              ry: 20,
            },
          }}
          series={[
            {data: [statusCounts.Fail], stack: 'A', label: 'Failed files'},
            {data: [statusCounts.Pass], stack: 'A', label: 'Fully classified'},
            {data: [statusCounts.Warning], stack: 'A', label: 'Some warnings'},
            {data: [statusCounts.Error], stack: 'A', label: ' Some errors '},
            {data: [(fileCount - statusCounts.Error - statusCounts.Warning - statusCounts.Pass - statusCounts.Fail)], stack: 'A', label: 'Awaiting processing'},
          ]}
          />
          </Col>
          </Row>
        </Container>
        <Container className='mt-2 rounded-box' style={{ padding: '0', background: 'none', overflowX: 'auto' }}>
          {showRenameSettings && (
            <RenameFiles onClose={handleCloseRenameSettingsModal} onDownload={handleRenameFiles} />
          )}
          {renderResultsTable()}
          
        </Container>
        <div className="secondary-background">
        {/* Your existing JSX */}
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showSpinner}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      </div>
      <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
    </div>
  );
};


export default Results;