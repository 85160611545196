import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Tab, Nav } from 'react-bootstrap';
import Chip from '@mui/material/Chip';
import { Portal } from '@mui/base/Portal';
import { useUserStandard } from '../../contexts/InitiateContexts';
import tagSuggestions from '../../baseStandards/tagSuggestions.json';

const DefineEnrichers = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [validated, setValidated] = useState(false);

  const { userStandard, dispatch } = useUserStandard();
  const [enrichers, setEnrichers] = useState({
    tags: [],
    searchTerms: [],
  });

  const container = useRef(null);

  // State to manage the input value and list of tags
  const [tagInput, setTagInput] = useState('');
  const [searchTermInput, setSearchTermInput] = useState('');

  useEffect(() => {
    // Initialize available tags from JSON file
    setAvailableTags(tagSuggestions);
  }, []);

  // useEffect to update sessionStorage when enrichers changes
  useEffect(() => {
    sessionStorage.setItem('enrichers', JSON.stringify(enrichers));
    console.log(enrichers);
  }, [enrichers]);

  // useEffect to update context when enrichers changes
  useEffect(() => {
    dispatch({ type: 'SET_ENRICHERS', payload: enrichers });
  }, [enrichers, dispatch]);

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const handleTagSelect = (tag) => {
    if (enrichers.tags.includes(tag)) {
      handleRemoveTag(tag)
    } else {
      handleAddTag(tag)
    }
  };

  // Function to handle removing a tag
  const handleRemoveTag = (tagToRemove) => {
    setEnrichers((prevEnrichers) => ({
      ...prevEnrichers,
      tags: prevEnrichers.tags.filter((tag) => tag !== tagToRemove),
    }));
  };

  const handleTagInputChange = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }

    setTagInput(event.target.value);
  };

  // Function to handle adding a tag
  const handleAddTag = (tag) => {
    if (tag.trim() !== '') {
      setEnrichers((prevEnrichers) => ({
        ...prevEnrichers,
        tags: [...prevEnrichers.tags, tag.trim()],
      }));
      setTagInput('');
    }
  };

  const isTagSelected = (tag) => {
    return enrichers.tags.includes(tag);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default action of Enter key
      handleAddTag(tagInput); // Manually trigger handleAddTag function
    }
  };

  const handleSearchTermInputChange = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }

    setSearchTermInput(event.target.value);
  };

  // Function to handle adding a search term
  const handleAddSearchTerm = (event) => {

    event.preventDefault(); // Prevent default button click behavior
    event.stopPropagation();
    const form = event.currentTarget;

    console.log(form)

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true); // Update the validation state
      return;
    }

    setValidated(false)

    setEnrichers((prevEnrichers) => ({
      ...prevEnrichers,
      searchTerms: [...prevEnrichers.searchTerms, { searchTerm: searchTermInput.trim(), value: '' }],
    }));
    setSearchTermInput('');
  };


  // Function to handle removing a search term
  const handleRemoveSearchTerm = (searchTermToRemove) => {
    setEnrichers((prevEnrichers) => ({
      ...prevEnrichers,
      searchTerms: prevEnrichers.searchTerms.filter(
        (term) => term.searchTerm !== searchTermToRemove
      ),
    }));
  };

  const enrichersData = {
    1: {
      title: 'Add tags',
      content: (
        <div className='text-start'>
          <p>Our AI can tag your documents if it finds they contain related content. Create your own tags, or pick from some of our suggestions.</p>
          <Form.Group className='mb-3'>
            <Form.Control
              type="text"
              placeholder="Enter tag"
              value={tagInput}
              onChange={handleTagInputChange}
              onKeyDown={handleKeyPress} // Handle Enter key press
            />
            <Form.Text className="text-muted">Press Enter to add tag</Form.Text>
          </Form.Group>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {enrichers.tags.map((tag) => (
              <Chip key={tag} label={tag} className="tag-selected mb-3 me-1 p-1" onDelete={() => handleRemoveTag(tag)} />
            ))}
          </div>
          <h5>Suggested tags</h5>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {availableTags.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                className={isTagSelected(tag) ? "tag-selected mb-1 me-1 p-1" : "filled mb-1 me-1 p-1"}
                onClick={() => handleTagSelect(tag)}
              />
            ))}
          </div>
        </div>
      )
    },
    2: {
      title: 'Add search terms',
      content: (
        <div className='text-start'>
          <p>Our AI can search your documents and extract additional information. For best results try and be as clear and descriptive as possible.</p>
          <div ref={container}></div>
          <Portal container={container.current}>
            <Form noValidate validated={validated} onSubmit={handleAddSearchTerm} > {/* Attach onSubmit event to the form */}
              <Form.Control
                required
                type="text"
                placeholder="Give your search term a title e.g. Published Date"
                value={searchTermInput}
                onChange={handleSearchTermInputChange}
                className='mb-2'
              />
              <Form.Control
                required
                as="textarea"
                rows={3}
                placeholder="Specify your search term e.g. 'find the date when the document was last published.'"
                value={searchTermInput}
                onChange={handleSearchTermInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Cannot be blank.
              </Form.Control.Feedback>
              <Button variant="primary" type='submit'>
                Add Search Term
              </Button>
            </Form>
          </Portal>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {enrichers.searchTerms.map((searchTerm) => (
              <Chip
                key={searchTerm.searchTerm}
                label={searchTerm.searchTerm}
                className={"tag-selected mb-1 me-1 p-1"}
                onDelete={() => handleRemoveSearchTerm(searchTerm.searchTerm)}
              />
            ))}
          </div>
        </div>
      )
    }
  }

  return (
    <Container className="rounded-box">
      <Tab.Container activeKey={selectedTab} onSelect={handleTabSelect}>
        <Row>
          <Col md={3}>
            <Nav variant="pills" className="flex-column">
              {Object.keys(enrichersData).map((tabKey) => (
                <Nav.Item key={tabKey} >
                  <Nav.Link
                    eventKey={parseInt(tabKey)}
                    className={`custom-nav-link ${selectedTab === parseInt(tabKey) ? 'active' : ''}`}
                  >
                    {enrichersData[tabKey].title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col md={9}>
            <Tab.Content >
              {Object.keys(enrichersData).map((tabKey) => (
                <Tab.Pane key={tabKey} eventKey={parseInt(tabKey)} >
                  <div className="text-center smaller-font">{enrichersData[tabKey].content}</div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default DefineEnrichers;

