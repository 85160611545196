import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Card, Nav, Tab } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCirclePlus, faCircleDown, faPassport, faEllipsis } from '@fortawesome/free-solid-svg-icons';


const DefineStandard = ({handleSetTemplate, setHasCodeParts, setUploadFromExcel }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [check, setCheck] = useState('BS EN ISO 19650-2:2018'); // Variable used to control default template. 

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  // Set the new template on component render.
  useEffect(() => {
    handleSetTemplate(check)
  },[check])

  const handleSetCheck = (template) => {
    setCheck(template); // sets the selected state for the selected card. 
  
    if (template === 'custom' || template === 'Excel Upload') {
      setHasCodeParts(false); // Set hasCodeParts to false
    } else {
      setHasCodeParts(true); // Set hasCodeParts to true for other templates
    }

    if (template === 'Excel Upload') {
      setUploadFromExcel(true)
    } else {
      setUploadFromExcel(false);
    }
  };
  

  const standardData = {
    1: {
      title: 'Start with an industry standard',
      content: (
        <div>
          <p className="text-start">Choose from a list of widely used industry standards to fast track your route to information assurance, and don't worry - in the next step you can tweak and modify the template to suit your needs.</p>
          <Row xs={1} md={3} className="g-2">
            
            <Col key={2} className='d-flex'>
              <Card onClick={() => handleSetCheck('BS EN ISO 19650-2:2018')} className={check === 'BS EN ISO 19650-2:2018' ? "card-template-custom-selected card-template" : "card-template-default card template card-template"}>
                <Card.Body className="text-start ">
                  <span className="fa-stack mb-2">
                    <FontAwesomeIcon icon={faCircle} className='icon-background-style fa-stack-2x' transform="scale-2" />
                    <FontAwesomeIcon icon={faPassport} className='icon-foreground-style fa-stack-1x' />
                  </span>
                  <Card.Title>BS EN ISO 19650-2:2018</Card.Title>
                  <Card.Text>The industry standard for the built environment sector.</Card.Text>
                </Card.Body>
                <Card.Footer className='text-start mb-2 p-2'>
                  <Button variant="secondary" href="/path/to/excel-template.xlsx" download>
                    <FontAwesomeIcon icon={faCircleDown} className='me-2' />
                    Download
                  </Button>
                </Card.Footer >
              </Card>
            </Col>
            <Col key={3} className='d-flex'>
              <Card className="card-template card-template-disabled">
                <Card.Body className="text-start ">
                  <span className="fa-stack mb-2">
                    
                    <FontAwesomeIcon icon={faCircle} className='icon-background-style fa-stack-2x' transform="scale-2" />
                    <FontAwesomeIcon icon={faPassport} className='icon-foreground-style fa-stack-1x' />
                  </span>
                  <Card.Title>DMRB GG 184 &nbsp;
                    <Badge bg="information" className=''>Coming soon</Badge>
                  </Card.Title>

                  <Card.Text>The UK National Highways standard.</Card.Text>
                </Card.Body>
                <Card.Footer className='text-start mb-2 p-2'>
                  <Button variant="secondary" href="/path/to/excel-template.xlsx" download>
                    <FontAwesomeIcon icon={faCircleDown} className='me-2' />
                    Download
                  </Button>
                </Card.Footer >
              </Card>
            </Col>
          </Row>
    </div>
      ),
    },
    2: {
      title: "Build or upload your own",
      content: (
        <div>
          <p className="text-start">Build your standard from scratch using our web builder, or upload from Excel by downloading, populating and reuploading our Excel template in the next step.</p>
          <Row xs={1} md={3} className="g-2">
          <Col key={1} className='d-flex'>
              <Card onClick={() => handleSetCheck('custom')} className={check === 'custom' ? "card-template-custom-selected card-template" : "card-template-default card template card-template"}>
                <Card.Body className="text-start ">
                  <span className="fa-stack mb-2">
                    <FontAwesomeIcon icon={faCircle} className='icon-background-style fa-stack-2x' transform="scale-2" />
                    <FontAwesomeIcon icon={faCirclePlus} className='icon-foreground-style fa-stack-1x' />
                  </span>
                  <Card.Title>Custom standard</Card.Title>
                  <Card.Text>Start from a blank canvas. Perfect for when you want to match to a client's standard or build your own. </Card.Text>

                </Card.Body>
              </Card>
            </Col>
            <Col key={1} className='d-flex'>
              <Card onClick={() => handleSetCheck('Excel Upload')} className={check === 'Excel Upload' ? "card-template-custom-selected card-template" : "card-template-default card template card-template"}>
                <Card.Body className="text-start ">
                  <span className="fa-stack mb-2">
                    <FontAwesomeIcon icon={faCircle} className='icon-background-style fa-stack-2x' transform="scale-2" />
                    <FontAwesomeIcon icon={faCirclePlus} className='icon-foreground-style fa-stack-1x' />
                  </span>
                  <Card.Title>Upload from Excel</Card.Title>
                  <Card.Text>If you've used Hoppa before and exported your standard to Excel, you can upload it here to use it again. </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            </Row>
      </div>
      )
      },
      3: {
        title: "Use AI to build one for you",
        content: (
          <div >
            <p className="text-start">Tell us some info about your project and our AI will generate a standard for you.</p>
            <FontAwesomeIcon icon={faEllipsis} className='mt-5 fa-5x blue-font' />
          <h3 className='pt-1 blue-font'>Coming soon...</h3>
        </div>
        )
        },
  };
  
  return (
    <div>
      <Container className="rounded-box">
      <Tab.Container activeKey={selectedTab} onSelect={handleTabSelect}>
        <Row>
        <Col md={3}>
          <Nav variant="pills" className="flex-column">
            {Object.keys(standardData).map((tabKey) => (
              <Nav.Item key={tabKey} >
                <Nav.Link
                  eventKey={parseInt(tabKey)}
                  className={`custom-nav-link ${selectedTab === parseInt(tabKey) ? 'active' : ''}`}
                >
                  {standardData[tabKey].title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
          <Col md={9}>
            <Tab.Content >
              {Object.keys(standardData).map((tabKey) => (
                <Tab.Pane key={tabKey} eventKey={parseInt(tabKey)} >
                     <div className="text-center smaller-font">{standardData[tabKey].content}</div>

                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      </Container>
    </div>
  );
};

export default DefineStandard;
