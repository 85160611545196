// InitiateContexts.jsx
import React, { createContext, useContext, useReducer } from 'react';

const UserStandardContext = createContext();

export const UserStandardProvider = ({ children }) => {
  const initialState = {
    classifiers: [],
    //tableData: {},
    enrichers: [],
    customStandardFlag: Boolean,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_CODE_PARTS':
        //console.log('Reducer: Setting CODE PARTS List with Payload:', action.payload);
        return { ...state, classifiers: action.payload };
      //case 'SET_TABLE_DATA':
        //return { ...state, tableData: action.payload };
      case 'SET_ENRICHERS':
        //console.log('Reducer: Setting Enrichers List with Payload:', action.payload);
        return { ...state, enrichers: action.payload };   
      default:
        return state;
    }
  };

  const [userStandard, dispatch] = useReducer(reducer, initialState);

  return (
    <UserStandardContext.Provider value={{ userStandard, dispatch }}>
      {children}
    </UserStandardContext.Provider>
  );
};

export const useUserStandard = () => {
  const context = useContext(UserStandardContext);
  if (!context) {
    throw new Error('useUserStandard must be used within a UserStandardProvider');
  }
  return context;
};
