import React, { useState, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import * as XLSX from 'xlsx';
import baseStandards from '../../baseStandards/baseStandards.json';
import { useUserStandard } from '../../contexts/InitiateContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Container, Row, Tab, Nav, Table, Form, OverlayTrigger, Tooltip, Badge, Modal } from 'react-bootstrap';
import CodePart from './CodePart';
import CodeValueEntry from './CodeValueEntry';
import Popup from '../shared/Popup';
import ClassificationStandardSummary from './ClassificationStandardSummary';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PlayCircleFilledWhiteTwoTone } from '@mui/icons-material';

const CustomiseStandard = ({ template, firstTimeConfig, handleSetFirstTimeConfig, clearStandard, handleSetClearStandard, handleSetToastMessage, hasCodeParts, uploadFromExcel, step, setAllCodePartsHaveValues }) => {
    const [baseStandard, setBaseStandard] = useState(null);
    const { userStandard, dispatch } = useUserStandard();
    const [codeParts, setCodeParts] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [showTableModal, setShowTableModal] = useState(false);
    const [activeCodePart, setActiveCodePart] = useState(null);
    const [isCollapsed, setCollapsed] = useState(true);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false); // State variable for showing/hiding the modal to clear standard
    const [showOverwriteConfirmation, setShowOverwriteConfirmation] = useState(false);
    const [openEditor, setOpenEditor] = useState(false);

    const [selectedTab, setSelectedTab] = useState(0);
    

    // Function to update the base standard on component mount. 
    useEffect(() => {

        // Check if this is the first time config and load either blank standard or template.
        if (firstTimeConfig) {

            console.log(template)

            // Find the section that matches the template
            const matchingSection = baseStandards.find(section => section.standard === template);

            // If a matching section is found, set baseStandard to its data, otherwise set to an empty array
            setBaseStandard(matchingSection ? matchingSection.content[0] : []);

            // Ensure the boolean is only reset when the user has clicked to this page. 
            if (template != null) {
                handleSetFirstTimeConfig()
            }

        }
        // Check if the user made a change on the Define Standard step and hence if the standard needs to be updated. 
        else if (clearStandard) {

            deleteAllCodeParts();

            // Find the section that matches the template
            const matchingSection = baseStandards.find(section => section.standard === template);

            // If a matching section is found, set baseStandard to its data, otherwise clear the base standard
            setBaseStandard(matchingSection ? matchingSection.content[0] : clearBaseStandard());

            // Set clearStandard to false. 
            handleSetClearStandard(false)
        }

    }, [template]);

    useEffect(() => {

        if (codeParts.length === 0 && baseStandard && baseStandard.classifiers) {
            console.log('Adding code parts from base standard:', baseStandard.classifiers);

            addBaseStandard();
        }

        else if (codeParts.length > 0 && baseStandard && baseStandard.classifiers) {
            addBaseStandard();
        }
    }, [baseStandard]);

    const clearBaseStandard = () => {
        setBaseStandard(null);
        handleSetClearStandard(false)
        
    };

    const addBaseStandard = useCallback(() => {
        if (baseStandard && baseStandard.classifiers) {
            const codePartsFromBaseStandard = baseStandard.classifiers.map(({ id, name, data }) => ({
                id,
                name,
                data,
            }));
    
            console.log('Code parts from base standard:', codePartsFromBaseStandard);
    
            setCodeParts((prevCodeParts) => {
                // Use the previous state to make sure you have the most up-to-date state
                console.log('Code parts after base standard update:', [...prevCodeParts, ...codePartsFromBaseStandard]);
                
                const updatedCodeParts = [...prevCodeParts, ...codePartsFromBaseStandard];
    
                // Dispatch updated code parts to the context
                dispatch({
                    type: 'SET_CODE_PARTS',
                    payload: updatedCodeParts,
                });
    
                return updatedCodeParts;
            });
        }
    }, [baseStandard, dispatch]);

    useEffect(() => {
        console.log("code parts available? ", hasCodeParts);
            
        if (hasCodeParts === true) {
            setOpenEditor(false);
        } else {
            setOpenEditor(true);
        }
    
        if (hasCodeParts === false && uploadFromExcel === true && step===1) {
            handleFileUpload()
        }
    }, [hasCodeParts, uploadFromExcel, step]);
    
    
    


    const addCodePart = () => {
        const customPartIndex = codeParts.filter((part) => part.id.startsWith('Custom')).length + 1;
        const newCodePart = { id: `Custom${customPartIndex}`, name: '', data: [{ code: '', description: '' }] };

        // Save the new code part to sessionStorage with blank tableData
        sessionStorage.setItem(newCodePart.id, JSON.stringify(newCodePart.data));

        // Update the state with the new code part
        setCodeParts((prevCodeParts) => {
            const updatedCodeParts = [...prevCodeParts, newCodePart];

            // Dispatch updated code parts to the context
            dispatch({
                type: 'SET_CODE_PARTS',
                payload: updatedCodeParts,
                tableData: { ...userStandard.tableData, [newCodePart.id]: newCodePart.data },
            });

            return updatedCodeParts;
        });
    };

    const handleShowConfirmation = () => {
        setShowDeleteConfirmation(true);
      };
    
      // Function to close the confirmation modal
      const handleCloseConfirmation = () => {
        setShowDeleteConfirmation(false);
      };

    const deleteAllCodeParts = () => {
        // Remove all code parts from sessionStorage
        codeParts.forEach((part) => {
            sessionStorage.removeItem(part.id);
        });

        // Clear the code parts state
        setCodeParts([]);

        // Dispatch an empty array to the context to clear code parts from userStandard
        dispatch({
            type: 'SET_CODE_PARTS',
            payload: [],
            tableData: {}, // Clear tableData as well
        });

        setShowDeleteConfirmation(false)

        clearBaseStandard();
    }

    const deleteCodePart = (id) => {
        // Remove the code part from the state
        setCodeParts((prevCodeParts) => prevCodeParts.filter((part) => part.id !== id));

        // Clear sessionStorage for the specific code part
        sessionStorage.removeItem(id);

        // Dispatch updated code parts to the context
        dispatch({
            type: 'SET_CODE_PARTS',
            payload: codeParts.filter((part) => part.id !== id),
            tableData: { ...userStandard.tableData, [activeCodePart]: sessionStorage.getItem(activeCodePart) },
        });

        dispatch({
            type: 'CUSTOM_STANDARD_FLAG',
            payload: true,
        })

    };

    const updateCodePart = (id, name, event) => {

        setCodeParts((prevCodeParts) => {
            const updatedCodeParts = prevCodeParts.map((part) => (part.id === id ? { ...part, name } : part))

            // Dispatch updated code parts to the context
            dispatch({
                type: 'SET_CODE_PARTS',
                payload: updatedCodeParts
            });

            return updatedCodeParts;
        })
    };

    const moveCodePart = (fromId, toId) => {
        setCodeParts((prevCodeParts) => {
            const updatedCodeParts = [...prevCodeParts];
            const fromIndex = updatedCodeParts.findIndex((part) => part.id === fromId);
            const toIndex = updatedCodeParts.findIndex((part) => part.id === toId);

            const [movedPart] = updatedCodeParts.splice(fromIndex, 1);
            updatedCodeParts.splice(toIndex, 0, movedPart);

            return updatedCodeParts;
        });
    };

    // Code value table updates
    const deleteRow = (codePartIndex, rowIndex) => {
        // Remove the row from the specified code part
        setCodeParts((prevCodeParts) =>
            prevCodeParts.map((codePart, index) =>
                index === codePartIndex
                    ? {
                          ...codePart,
                          data: codePart.data.filter((_, idx) => idx !== rowIndex),
                      }
                    : codePart
            )
        );
    };

    const addRow = (codePartIndex) => {
        // Find the code part by its index
        const codePart = codeParts[codePartIndex];
        
        // Create a new row with empty values
        const newRow = { code: '', description: '' };
        
        // Update the state to add the new row to the specified code part
        setCodeParts((prevCodeParts) =>
            prevCodeParts.map((prevCodePart, index) =>
                index === codePartIndex
                    ? {
                        ...prevCodePart,
                        data: [...prevCodePart.data, newRow],
                    }
                    : prevCodePart
            )
        );
    };
  
    
    const handleSave = (codePartIndex) => {
        // Update sessionStorage with the latest data
        const updatedSessionData = { ...userStandard.tableData, [codePartIndex]: codeParts[codePartIndex].data };
        sessionStorage.setItem(codeParts[codePartIndex].id, JSON.stringify(updatedSessionData));
    
        // Call the onSave prop with the codePartIdentifier and the updated tableData
        handleSaveCodeValueEntry(codeParts[codePartIndex].id, codeParts[codePartIndex].data);
    };
    
    const updateRow = (codePartIndex, rowIndex, field, value) => {
        setCodeParts((prevCodeParts) =>
            prevCodeParts.map((prevCodePart, index) =>
                index === codePartIndex
                    ? {
                          ...prevCodePart,
                          data: prevCodePart.data.map((rowData, rowIdx) =>
                              rowIdx === rowIndex ? { ...rowData, [field]: value } : rowData
                          ),
                      }
                    : prevCodePart
            )
        );
    
        // Call handleSave when a field is updated
        handleSave(codePartIndex);
    };
    
    const saveRow = (codePartIndex) => {
        // Call handleSave when onBlur event is triggered
        handleSave(codePartIndex);
    };
    
    const handlePasteFromExcel = (e) => {
        console.log('Pasting from Excel...');
        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text');
        console.log('Pasted data:', pastedData);
    
        // Parse the pasted data and update the code part data
        const parsedRows = pastedData.split('\n').map((row) => {
            const [code, description] = row.split('\t');
    
            // Check if description is defined before calling replace method
            const cleanedDescription = description ? description.replace(/[\r\n]/g, '') : '';
    
            return { code: code || '', description: cleanedDescription || '' };
        });

        console.log('Parsed rows:', parsedRows);
    
        // Add the parsed rows to the specified code part data
        setCodeParts((prevCodeParts) => {
            const updatedCodeParts = prevCodeParts.map((codePart, index) => {
                console.log(activeCodePart)
                if (index === activeCodePart) {
                    console.log('Updating code part:', codePart);
                    return { ...codePart, data: [...codePart.data, ...parsedRows] };
                } else {
                    return codePart;
                }
            });
    
            console.log('Code parts before update:', prevCodeParts);
            console.log('Code parts after update:', updatedCodeParts);
    
            return updatedCodeParts;
        });
    };

    useEffect(() => {
        console.log("Code parts: ", codeParts);
    }, [codeParts]);

    const handleShowTableModal = (codePartId) => {
        setShowTableModal(true);
        setActiveCodePart(codePartId);
    };

    const handleHideTableModal = () => {
        setShowTableModal(false);
        setActiveCodePart(null);
    };

    const handleSaveCodeValueEntry = (codePartIdentifier, tableData) => {
        // Handle saving the tableData for the specific code part using session storage
        sessionStorage.setItem(codePartIdentifier, JSON.stringify(tableData));

        // Use the latest state from the context instead of userStandard.tableData directly
        const updatedTableData = { ...userStandard.tableData, [codePartIdentifier]: tableData };

        // Check if the code part exists in userStandard.classifiers
        const existingCodePartIndex = userStandard.classifiers.findIndex(
            (classifier) => classifier.id === codePartIdentifier
        );

        // If the code part exists, update its data; otherwise, log an error (or handle it as needed)
        if (existingCodePartIndex !== -1) {
            // Update the existing code part's data
            const updatedClassifiers = [...userStandard.classifiers];
            updatedClassifiers[existingCodePartIndex].data = tableData;

            // Dispatch the updated context with the new code parts
            dispatch({
                type: 'SET_CODE_PARTS',
                payload: updatedClassifiers,
                tableData: updatedTableData,
            });

            // Update the context with the new table data
            dispatch({
                type: 'SET_TABLE_DATA',
                payload: updatedTableData,
            });
        } else {
            console.error(`Code part with identifier ${codePartIdentifier} not found.`);
            // Optionally, you can handle the error or log it as needed.
        }
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            // Clear sessionStorage for each code part
            codeParts.forEach((part, index) => {
                sessionStorage.removeItem(`part_${index + 1}`);
            });
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [codeParts]);

    // Export standard to Excel for future use by user
    const handleExportToExcel = () => {
        const wb = XLSX.utils.book_new();
        const fileName = 'My_Standard.xlsx';

        // Iterate over codeParts and add a sheet for each code part
        codeParts.forEach((codePart) => {
            const codePartData = codePart.data;

            console.log(codePartData);

            // Ensure codePartData is an array
            const data = Array.isArray(codePartData) ? codePartData : [];

            // console.log(`CodePart: ${codePart.name}, Data: `, data); // Log the data

            const ws = XLSX.utils.json_to_sheet(data);
            const sheetName = codePart.name;
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
        });

        XLSX.writeFile(wb, fileName);
    };

    useEffect(() => {
        // Clear sessionStorage for each code part when the component is dismounted (i.e. page refresh)
        codeParts.forEach((codePart) => {
            sessionStorage.removeItem(codePart.id);
        });
    }, []);

    const handleFileUpload = () => {
        if (codeParts.length>0) {
            if (showOverwriteConfirmation) {
                setShowOverwriteConfirmation(false)
                document.getElementById('file-upload').click()
            } else {
                setShowOverwriteConfirmation(true)
            }
        } else {
            document.getElementById('file-upload').click()
        }
    }

    console.log(codeParts)

    const FileUpload = (event) => {

        console.log("FileUpload function called");

        console.log("Event object:", event);

        if (!event.target.files || event.target.files.length === 0) {
            // No file uploaded, so return early
            console.log("No file uploaded");
            return;
        }

        const file = event.target.files[0];
        console.log("Selected file:", file);

        if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls')) {
            handleSetToastMessage('Invalid file type. Please upload an Excel file.');
            return;
        }

        const reader = new FileReader();

        reader.onload = (event) => {

            try {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                // Assuming each sheet corresponds to a code part
                const codeParts = workbook.SheetNames.map((sheetName, index) => {
                    const sheet = workbook.Sheets[sheetName];
                    const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                    // Skip the first row if it contains headers
                    const codePartData = sheetData.slice(1).map((row) => ({
                        code: row[0] || '',
                        description: row[1] || '',
                    }));

                    // Generate the unique ID
                    const sheetId = `ID${index + 1}-${sheetName}`;

                    return {
                        id: sheetId,
                        name: sheetName,
                        data: codePartData,
                    };
                });

                handleSetClearStandard(true)

                deleteAllCodeParts();

                // Update base standard with the uploaded code parts
                setBaseStandard({ classifiers: codeParts, enrichers: {} });

            } catch (error) {
                handleSetToastMessage('An error occurred. Check contents of file and try again.')
                console.error('Error parsing Excel file:', error);
            } finally {
                reader.onload = null; // Reset the onload event handler
            }
        }

        reader.readAsArrayBuffer(file);
        event.target.value = '' // Reset the value of the file input

    };

    // const { getRootProps, getInputProps } = useDropzone({
    //     accept: '.xlsx, .xls',
    //     onDrop: handleFileUpload,
    // });

    const toggleCollapse = () => {
        setCollapsed(!isCollapsed);
    };

    const isTabDataValid = () => {
        // Iterate through codeParts to check each tab's data
        for (const codePart of codeParts) {
            for (const row of codePart.data) {
                if (!row.code || !row.description) {
                    return false; // Return false if any row in any tab has null data
                }
            }
        }

        return true;
        
    };

    const isValid = isTabDataValid();

    setAllCodePartsHaveValues(isValid);

    const handleProceed = () => {
        if (isTabDataValid()) {
            // Proceed to the next step
        } else {
            // Show an error message or prevent the user from proceeding
            handleSetToastMessage('Please fill in all data for each code part before proceeding.');
        }
    };

    return (
        <div >
            <div className={openEditor ? "code-editor-visible" : "code-editor-hidden"}>
            <Container className="rounded-box position-relative">
                <Col md={10}>
                <p className="">Create or edit your <b>Classifiers</b>. Choose the main categories for your files to be sorted against. Ensure the sequential order of the <b>Classifiers</b> conforms to your file naming convention.</p>
                </Col>
                <DndProvider backend={HTML5Backend}>
                    <Container className="">
                    <Row className="mt-3 mb-3">
                    <Col key={0} className="d-flex">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="tooltip-save">If you have spent time customising your standard, be sure to save a copy to Excel for easy upload next time.</Tooltip>}
                        >
                            <Button onClick={handleExportToExcel} className="btn-excel">
                                <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                                &nbsp;&nbsp;Save standard
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="tooltip-upload">If you've previously saved a standard to Excel you can upload it here and use it again!</Tooltip>}
                        >
                        <Button className="btn-excel" onClick={handleFileUpload}>
                            <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                            &nbsp;&nbsp;Upload standard
                        </Button>
                        </OverlayTrigger>
                        <input id="file-upload" type="file" accept=".xls,.xlsx" onChange={FileUpload} style={{ display: 'none' }} />
                    </Col>
                    <Col className='gr-0 text-end'>
                        <Button variant="secondary" disabled={codeParts.length === 0} onClick={() => setShowDeleteConfirmation(true)}>Clear all</Button>
                        <Button variant="primary" onClick={addCodePart} className="">Add Code Part</Button>
                    </Col>
                        </Row>
                        
                        <Row className="flex-nowrap">
                            {codeParts.map((codePart) => (
                                <CodePart
                                    key={codePart.id}
                                    codePart={codePart}
                                    moveCodePart={moveCodePart}
                                    updateCodePart={updateCodePart}
                                    deleteCodePart={deleteCodePart}
                                    showTableModal={() => handleShowTableModal(codePart.id)}
                                />

                            ))}
                        </Row>
                        {/* Render CodeValueEntry component if showTableModal is true */}
                        {showTableModal && (
                            <CodeValueEntry
                                show={showTableModal}
                                onHide={handleHideTableModal}
                                onSave={handleSaveCodeValueEntry}
                                codePartIdentifier={activeCodePart}
                                baseStandard={baseStandard}
                            />
                        )}
                        {/* Render DeleteConfirmation component if showDeleteConfirmation is true */}
                        <Modal show={showDeleteConfirmation} onHide={handleCloseConfirmation}>
                            <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            Are you sure you want to clear all code parts?
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseConfirmation}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={deleteAllCodeParts}>
                                Clear All
                            </Button>
                            </Modal.Footer>
                        </Modal>
                        {/*<Popup
                            show={showOverwriteConfirmation}
                            onHide={() => setShowOverwriteConfirmation(false)}
                            onConfirm={handleFileUpload}
                            callToAction='Overwrite All'
                            title='Confirmation'
                            content='Selecting a new template will overwrite your current standard. Are you sure you want to continue?'
                    />*/}
                    
                    </Container>
                    {/*<ClassificationStandardSummary
                        userStandard={userStandard}
                        isCollapsed={isCollapsed}
                        toggleCollapse={toggleCollapse}
                        />*/}
                </DndProvider>
            </Container>
            </div>
            
            <Container className="mt-3 rounded-box position-relative">
            <Row>
                <Col xs={10}>
                    <p>We will match each file to the most appropriate <b>Reference Code</b> for each <b>Classifier</b>. Each <b>Classifier</b> needs at least one option for our AI to choose from. 
                    You can copy and paste <b>Reference Codes</b> and <b>Descriptions</b> from Excel if you have them listed elsewhere.</p>
                </Col>
                <Col xs={2}>
                    <Button
                        variant="secondary"
                        className="position-absolute top-0 end-0 mt-2 me-1"
                        style={{ color: 'white' }}
                        onClick={() => setOpenEditor(!openEditor)}
                        >
                        {openEditor ? 'Close Editor' : 'Classifier Editor'}
                    </Button>
                </Col>
            </Row>
                
                            <Tab.Container activeKey={`codePart-${selectedTab}`}>
                    <Row>
                        <Col md={3}>
                            <Nav variant="pills" className="flex-column">
                                {codeParts.map((codePart, index) => (
                                    <Nav.Item key={index}>
                                        <Nav.Link 
                                            eventKey={`codePart-${index}`}
                                            className={selectedTab === index ? 'active custom-nav-link' : 'custom-nav-link'}
                                            onClick={() => setSelectedTab(index)}
                                        >
                                            {codePart.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col md={9}>
                        <Tab.Content>
                            {codeParts.map((codePart, index) => (
                                <Tab.Pane key={index} eventKey={`codePart-${index}`} onSelect={() => setActiveCodePart(index)}>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Reference Code</th>
                                                <th>Description</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {codePart.data.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            value={row.code}
                                                            onChange={(e) => updateRow(index, rowIndex, 'code', e.target.value)}
                                                            onBlur={() => saveRow(index)}
                                                            onPaste={handlePasteFromExcel}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            value={row.description}
                                                            onChange={(e) => updateRow(index, rowIndex, 'description', e.target.value)}
                                                            onBlur={() => saveRow(index)}
                                                            onPaste={handlePasteFromExcel}
                                                        />
                                                    </td>
                                                    <td>
                                                    <Button 
                                                        variant="danger"
                                                        onClick={() => deleteRow(index, rowIndex)}
                                                        disabled={codePart.data.length === 1} // Disable the button if there's only one row
                                                    >
                                                        Delete
                                                    </Button>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Button 
                                        onClick={() => addRow(index)}
                                        >Add Row</Button>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>

        </div>
    );
};

export default CustomiseStandard;