import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup, FormControl } from 'react-bootstrap';

const RenameFiles = ({ onClose, onDownload }) => {
  const [codeSettings, setCodeSettings] = useState({
    documentSeparator: '-',
    numberingSystem: {
      digits: 6,
    },
  });

  const handleDownload = () => {
    // Call the onDownload function with codeSettings
    onDownload(codeSettings);
    // Close the modal
    onClose();
  };

  const handleInputChange = (e) => {
    // Update the codeSettings based on user input
    const { name, value, type, checked } = e.target;
    setCodeSettings((prevSettings) => {
      if (name === 'numberingSystem') {
        return {
          ...prevSettings,
          [name]: {
            ...prevSettings[name],
            digits: type === 'button' ? (checked ? prevSettings[name].digits + 1 : prevSettings[name].digits - 1) : value,
          },
        };
      } else {
        return {
          ...prevSettings,
          [name]: type === 'checkbox' ? checked : value,
        };
      }
    });
  };

  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Document Renaming Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="documentSeparator">
            <Form.Label>Document Code Separator</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter separator"
              name="documentSeparator"
              value={codeSettings.documentSeparator}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="numberingSystem">
            <Form.Label>Sequential Number Digit Length</Form.Label>
            <InputGroup>
              <FormControl
                type="text"
                placeholder="Enter number of digits"
                name="numberingSystem"
                value={codeSettings.numberingSystem.digits}
                readOnly
              />
              <Button
                variant="outline-secondary"
                name="numberingSystem"
                onClick={(e) => handleInputChange({ target: { name: 'numberingSystem', type: 'button', checked: false } })}
              >
                -
              </Button>
              <Button
                variant="outline-secondary"
                name="numberingSystem"
                onClick={(e) => handleInputChange({ target: { name: 'numberingSystem', type: 'button', checked: true } })}
              >
                +
              </Button>
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleDownload}>
          Rename
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RenameFiles;
