import React, { useState, useEffect } from 'react';
import { Button, Card, ProgressBar, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheck, faCircle, faCircleExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import {FileIcon, defaultStyles} from 'react-file-icon';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const FileCard = ({fileName, fileType, total, uploadProgress, handleDelete, status}) => {
    const [statusIcon, setStatusIcon] = useState(null)
    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {

        if (status === 'processing') {
            setShowSpinner(true)
        } else {
            setShowSpinner(false)
        }
        
    },[status])

    useEffect(() => {
        if (status === 'processing' && uploadProgress > 99) {
            setStatusIcon(
            <Chip variant='outlined' label='Writing to storage...' className='me-3' />
            )
        }
        else if (status === 'completed') {
            setStatusIcon(
                <div className='d-flex align-items-center'>
                    <Chip color='success' variant='outlined' label='Upload successful' className='me-3' />
                    <Button variant="light" onClick={() => handleDelete(fileName)} title='Delete file'>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </div>
            )
        } else if (status === 'failed') {
            setStatusIcon(
                <div className='d-flex align-items-center'>
                    <Chip color='error' variant='outlined' label='Upload failed' className='me-3' />
                    <Button variant="light" onClick={() => handleDelete(fileName)} title='Delete file'>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </div>
            )
        }
    }, [status, uploadProgress, fileName, handleDelete])

    // Define styles for unknown file types (blank file icon)
  const unknownFileStyle = {
    ...defaultStyles.default,
  };

    return (
        <Card key={1} className='mt-1 mb-1'>
            <Card.Body>
                <Row className='d-flex align-items-center'>
                    <Col xs={1} className='d-flex justify-content-center'>
                        <FileIcon
                        extension={fileType}
                        {...defaultStyles[fileType]}
                        style={unknownFileStyle}
                        labelUppercase
                        />
                    </Col>
                    <Col>
                        <Card.Title className='mb-0'>{fileName}</Card.Title>
                            <p className='fst-italic'>{total}</p>
                    </Col>
                    <Col xs={5} className='d-flex justify-content-end align-items-center'>
                        {statusIcon}
                        {showSpinner && (
                        <CircularProgressWithLabel value={uploadProgress} suffix={'%'}/>
                    )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default FileCard;