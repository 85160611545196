import { React, useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../../config'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import HeaderWithClipboard from './HeaderWithClipboard';
import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Modal, Button } from 'react-bootstrap';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DeleteIcon from '@mui/icons-material/Delete';
import Toast from 'react-bootstrap/Toast';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LogoutButton from './LogoutButton'


import { useParams, useLocation, useNavigate } from "react-router-dom";

// HeaderBar is rendered on each page, and props are passed to it to render different information on each page's header
function HeaderBar({ homeLink, homeText, isBasic }) {
  const { user: username, sessionId: sessionGuid } = useParams();
  const navigate = useNavigate(); // for handling page routing within a function. 
  const location = useLocation();
  const pathname = location.pathname;

  // Check if 'results' is present in the URL pathname
  const showDeleteButton = pathname.includes('results');

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showHelpModal, setShowHelpModal] = useState(false)
  const [showProblemModal, setShowProblemModal] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  const deleteSession = async () => {

    setShowDeleteModal(false)
    setShowSpinner(true)

    const response = await axios.get(`${API_BASE_URL}/api/DeleteSession?containerName=${username}&virtualFolder=${sessionGuid}`);

    if (response) {
      setToastMessage("Session deleted. You will now be redirected.")
    } else {
      setToastMessage('Failed to delete session. Contact support.')
    }

    setShowSpinner(false);
    setShowToast(true);

    if (response) {

      setTimeout(() => {navigate('/')}, 2000) // Navigate back to homepage.

    }
  }

  return (
    <div>
      <div className='secondary-background'>
        <Navbar expand="lg" className="header-bar">
          <Container>
            <Navbar.Brand href={homeLink}>{homeText}</Navbar.Brand>
            {!isBasic && 
            <div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
              
              <Navbar.Text className='d-flex align-items-center'>
                {sessionGuid && (
                  <div className='d-flex'>
                    <div className='me-2'>
                      Session key:
                    </div>
                    <div className='pe-2'>
                      <HeaderWithClipboard style={{ color: 'inherit' }} fieldValue={sessionGuid} />
                    </div>
                  </div>
                )}
                <div style={{ borderRight: '1px solid white', borderLeft: '1px solid white' }}>
                  {showDeleteButton && <IconButton title='Delete session' aria-label="delete" style={{ border: 'solid 1px white', marginLeft: '1rem'}} onClick={() => setShowDeleteModal(true)}>
                    <DeleteIcon style={{ color: 'white' }} fontSize='small' />
                  </IconButton>}
                  <IconButton title='Get help' aria-label="help" style={{ border: 'solid 1px white', marginLeft: '1rem', marginRight: '1rem' }} onClick={() => setShowHelpModal(true)}>
                    <QuestionMarkIcon style={{ color: 'white' }} fontSize='small' />
                  </IconButton>
                  <IconButton title='Provide feedback' aria-label="incident" style={{ border: 'solid 1px white', marginRight: '1rem' }} onClick={() => setShowProblemModal(true)}>
                    <ReportProblemIcon style={{ color: 'white' }} fontSize='small' />
                  </IconButton>
                </div>
                <AccountCircleIcon className='ms-2 me-1' />
                {username}
                <LogoutButton />
              </Navbar.Text>
            </Navbar.Collapse>
            </div>
            }
          </Container>
        </Navbar>
      </div>
      <Modal show={showHelpModal} onHide={()=>setShowHelpModal(false)} centered>
        <Modal.Header><Modal.Title>Need some help?</Modal.Title></Modal.Header>
        <Modal.Body>
          <p>We're working on developing better written and in-app guidance.</p>
          <p>For now, please contact us at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-secondary" onClick={() => setShowHelpModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showProblemModal} onHide={() => setShowProblemModal(false)} centered>
        <Modal.Header><Modal.Title>Something not working as expected?</Modal.Title></Modal.Header>
        <Modal.Body>
          <p>Please contact us at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a> with details of your situation and a member of the team will respond. Remember to quote your session key.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-secondary" onClick={() => setShowProblemModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header><Modal.Title>Are you sure?</Modal.Title></Modal.Header>
        <Modal.Body>
          <p>This will permanently delete all uploaded files, standards and results. Once deleted your session cannot be restored.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="btn btn-primary" onClick={() => deleteSession(false)}>Delete session</Button>
        </Modal.Footer>
      </Modal>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toast onClose={() => setShowToast(false)} delay={3000} show={showToast} autohide className="position-fixed top-0 start-50 translate-middle-x">
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </div>
  );
}

export default HeaderBar;
