import React, { useState } from 'react';
import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import { ResponsiveChord } from '@nivo/chord';
import circlePackingData from './dummyChartData/circlePacking.json';
import responsiveChordData from './dummyChartData/responsiveChord.json';

const ResultsCharts = ({ data }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ height: '500px', width: '100%' }}>
      <MyResponsiveCirclePacking circlePackingData={circlePackingData} />
    </div>
    <div style={{ height: '500px', width: '100%' }}>
      <MyResponsiveChord responsiveChordData={responsiveChordData} />
    </div>
  </div>
);

const MyResponsiveCirclePacking = ({ circlePackingData /* see data tab */ }) => {

  return (
    <ResponsiveCirclePacking
      data={circlePackingData}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      id="name"
      value="loc"
      colors={{ scheme: 'nivo' }}
      childColor={{
        from: 'color',
        modifiers: [['brighter', 0.4]],
      }}
      padding={4}
      enableLabels={true}
      labelsFilter={(n) => 2 === n.node.depth}
      labelsSkipRadius={10}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.5]],
      }}
      defs={[
        {
          id: 'lines',
          type: 'patternLines',
          background: 'none',
          color: 'inherit',
          rotation: -45,
          lineWidth: 5,
          spacing: 8,
        },
      ]}
      fill={[
        {
          match: {
            depth: 1,
          },
          id: 'lines',
        },
      ]}
    />
  );
};

const MyResponsiveChord = ({ responsiveChordData /* see data tab */ }) => (
  <ResponsiveChord
    data={responsiveChordData}
    keys={['Architecture', 'Structures', 'Civils', 'Geotech', 'PM']}
    margin={{ top: 60, right: 60, bottom: 90, left: 60 }}
    valueFormat=".2f"
    padAngle={0.02}
    innerRadiusRatio={0.96}
    innerRadiusOffset={0.02}
    inactiveArcOpacity={0.25}
    arcBorderColor={{
      from: 'color',
      modifiers: [['darker', 0.6]],
    }}
    activeRibbonOpacity={0.75}
    inactiveRibbonOpacity={0.25}
    ribbonBorderColor={{
      from: 'color',
      modifiers: [['darker', 0.6]],
    }}
    labelRotation={-90}
    labelTextColor={{
      from: 'color',
      modifiers: [['darker', 1]],
    }}
    colors={{ scheme: 'nivo' }}
    motionConfig="stiff"
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 70,
        itemWidth: 80,
        itemHeight: 14,
        itemsSpacing: 0,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        symbolSize: 12,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ]}
  />
);

export default ResultsCharts;
