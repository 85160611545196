import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HeaderBar from './shared/HeaderBar';
import { useSession } from '../contexts/SessionContexts';
import API_BASE_URL from '../config';
import { Container, Row, Col, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const WelcomeUser = () => {
  const { user, isAuthenticated, logout } = useAuth0();
  const { sessionGuid } = useSession();
  const [sessions, setSessions] = useState([]);  // State to store user sessions
  const [otherSessions, setOtherSessions] = useState([]); // State to store other sessions
  const [APIError, setAPIError] = useState(null);  // For error handling
  const [credits, setCredits] = useState(0); 
  const navigate = useNavigate();  // React Router's navigation hook

  const firstName = user?.nickname || user?.name;

  const sanitizeUserId = (userId) => {
    return userId.split('|')[1];  // Split by '|' and return the second part
  };

  const userId = sanitizeUserId(user?.sub);

  // Get Auth0 permissions object (custom action) and fallback to blank object if not there (i.e. for new users who haven't yet received permissions)
  const auth0AppMetadata = user?.['https://hoppa.ai/app_metadata'] || {};  // Default to an empty object
  const permissions = auth0AppMetadata.permissions || {};  // Default to an empty object for permissions
  const createSessionPermission = permissions?.initiate_new_session ?? false;  // Default to false if not found

  // Extract 'Other Sessions' from app_metadata
  useEffect(() => {
    if (auth0AppMetadata?.permissions?.session_access) {
      const otherSessionIds = auth0AppMetadata.permissions.session_access.split('/');
      // Assuming that session_access contains the session in "container/session" format
      setOtherSessions([{
        container: otherSessionIds[0],
        sessionId: otherSessionIds[1]
      }]);
    }
  }, [auth0AppMetadata]);

  // Function to handle creating a new session
  const handleCreateSession = () => {
    try {
      let userId = user.sub;  // user.sub holds the Auth0 user_id (unique identifier)
      userId = sanitizeUserId(userId);

      // Use axios to call the backend API
      axios.post(`${API_BASE_URL}/api/NewSessionTrigger`, null, {
        params: { userId: userId }
      })
      .then((response) => {
        console.log("New session container created successfully:", response.data);

        // Navigate to the session initiation route using user_id
        navigate(`/${userId}/${sessionGuid}/initiate`);
      })
      .catch((error) => {
        setAPIError('Failed to create a new session.');
        console.error('Error creating session:', error);
      });

    } catch (error) {
      setAPIError('Failed to create a new session.');
      console.error('Error creating session:', error);
    }
  };

  // Function to fetch user sessions from the backend
  const handleGetUserSessions = () => {
    axios.post(`${API_BASE_URL}/api/GetUserSessions`, null, {
      params: { userId: userId }
    })
    .then((response) => {
      console.log("User sessions fetched successfully:", response.data);
      setSessions(response.data);  // Store the sessions in state
    })
    .catch((error) => {
      console.error('Error fetching user sessions:', error);
      setAPIError("It looks like you're new here! Create a session to get started.");
    });
  };

  // Fetch sessions when the component mounts or userId changes
  useEffect(() => {
    if (userId) {
      handleGetUserSessions();
    }
  }, [userId]);

  // Function to handle navigation to the results page of a session
  const handleSessionClick = (sessionId, container) => {
    // If the session comes from 'Other Sessions', use the provided container
    const sessionContainer = container || userId;
    navigate(`/${sessionContainer}/${sessionId}/results`);
  };

  const showEmptyMessage = sessions.length === 0 && otherSessions.length === 0;

  return (
    <div className="secondary-background">
      <HeaderBar homeLink="/" homeText="hoppa" />
      <div className="full-width-primary-section">
        <Container style={{ padding: '0px' }}>
          <Row className="d-flex align-items-stretch">
            <Col xs={6}>
              <div className='mt-2 rounded-box' style={{ height: "96%" }}>
                <h2>Welcome, {firstName}!</h2>
                <button className="btn btn-secondary mt-4"
                  onClick={handleCreateSession}
                  disabled={!createSessionPermission}>
                  Create new session
                </button>
               
              </div>
            </Col>
            <Col xs={6}>
              <div className='mt-2 rounded-box' style={{ padding: '20px', background: 'white' }}>
                <h2>Credits</h2>
                <h1>{credits}</h1>
                <p>Contact us at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a> to top up your credits!</p>
              </div>
            </Col>
          </Row>
        </Container>
        {showEmptyMessage ? (
          <Container className='mt-2 rounded-box' style={{ padding: '20px', background: 'white' }}>
            <h5>✨ It looks like you're new here! ✨</h5>
            <p>We are currently in beta release and your account will need to be validated before you can start using our services ✅</p>
            <p>To get started you will need to be granted access to an existing project, or start your own 🚀</p>
            <p>If your organisation is new to hoppa, contact us for a free trial 👌</p>
          </Container>
        ) : (
          <>
            <Container className='mt-2 rounded-box' style={{ padding: '20px', background: 'white', overflowX: 'auto' }}>
              <h5>Owned Sessions:</h5>
              {APIError && <div className="alert alert-danger">{APIError}</div>}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Session ID</th>
                    <th>Created On</th>
                    <th>File Count</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sessions.map(session => (
                    <tr key={session.sessionId}>
                      <td>{session.sessionId}</td>
                      <td>{session.createdOn}</td>
                      <td>{session.blobCount}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleSessionClick(session.sessionId)}
                        >
                          View Results
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Container>
            
            <Container className='mt-2 rounded-box' style={{ padding: '20px', background: 'white', overflowX: 'auto' }}>
              <h5>Shared With You:</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Session ID</th>
                    <th>Container</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {otherSessions.map(session => (
                    <tr key={session.sessionId}>
                      <td>{session.sessionId}</td>
                      <td>{session.container}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleSessionClick(session.sessionId, session.container)}
                        >
                          View Results
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Container>
          </>
        )}
      </div>
    </div>
  );
};

export default WelcomeUser;
