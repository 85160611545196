import { useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Select, MenuItem, Typography, Box, Button, Chip, Switch, FormControlLabel } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import SaveIcon from '@mui/icons-material/Save';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { Save } from '@mui/icons-material';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const ResultsTable = ({ columns, data, setTableData, editedRow, setEditedRow, userStandard, onSaveRow, onRenameFiles, showTags, showSearchTerms, onToggleTags, onToggleSearchTerms, tags, onDownloadFiles, isModifiedName, searchTermNames }) => {

  // console.log("Is modified name: ", isModifiedName)
  // Function to handle saving the edited row
  const handleSaveRow = async () => {
    if (editedRow) {
      // Call the onSaveRow prop with the edited row
      await onSaveRow(Object.values(editedRow));
      console.log(Object.values(editedRow));
      // Clear the edited row state
      setEditedRow(null);
    }
  };

  const handleRenameFile = () => {
    onRenameFiles();
  };

  const handleDownloadFiles = () => {
    onDownloadFiles();
  }

  const handleToggleTags = () => {
    onToggleTags();
  };

  const handleToggleSearchTerms = () => {
    onToggleSearchTerms();
  };

  // Function to handle editing a cell
  const handleEditCell = async (newValue, rowId) => {
    // Find the edited row in the editedRow state and update its value
    const updatedEditedRow = {
      ...editedRow,
      [rowId]: {
        ...editedRow[rowId], // Retrieve existing edited row data for the given rowId
        classifierCode: newValue, // Update the value for the specified column
      },
    };

    // Log the updated row data before setting the state
    console.log("Updated Row:", updatedEditedRow[rowId]);

    // Update the edited row state with the specific edited row
    setEditedRow(updatedEditedRow);
  };



  //console.log('Columns:', columns);
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  // Render detail panel content
  const DetailPanelContent = ({ row }) => (
    <Typography
      component="div"
      className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft"
    >
      {row.original.description && row.getIsExpanded() && (
        <>
          {/* Display description in the first column */}
          <b>Document Summary:</b>
          {row.original.description}
        </>
      )}
    </Typography>
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableColumnPinning: true,
    initialState: {
      columnPinning: { left: ['newFileName'] },
    },
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Align components in one row with space between
            alignItems: 'center', // Align items vertically
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          {/* Buttons */}
          <div style={{ display: 'flex', gap: '16px' }}>
            <Button
              onClick={handleSaveRow}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              disabled={!editedRow? true: false}
              className='btn btn-primary'
            >
              <div className='buttontext'>Save Edits</div>
            </Button>
            <Button
              onClick={handleRenameFile}
              variant="contained"
              color="primary"
              startIcon={<DriveFileRenameOutlineIcon />}
              disabled={editedRow? true : false}
              className='btn btn-primary'
            >
              <div className='buttontext'>Generate File Codes</div>
            </Button>
            <Button
              className='btn btn-secondary'
              onClick={handleDownloadFiles}
              startIcon={<FolderZipIcon />}
              variant="contained"
              // Disable if cell edits have not been saved and new file names have not been generated
              disabled={(editedRow || Object.keys(isModifiedName).length === 0)? true : false}
            >
              <div className='buttontext'>Export Coded Files</div>
            </Button>
            <Button
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              className='btn btn-secondary'
            >
              <div className='buttontext'>Export All</div>
            </Button>
            <Button
              disabled={
                (!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
    )? true : false}
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              className='btn btn-secondary'
            >
              <div className='buttontext'>Export Selected</div>
            </Button>
          </div>

          {/* Tags and Search Terms */}
          <div style={{ display: 'flex', gap: '16px', marginLeft: '32px' }}>
            {/* Toggle switch for Tags */}
            {tags && tags.length > 0 && (
              <FormControlLabel
                control={<Switch checked={showTags} onChange={handleToggleTags} color="secondary" />}
                label="Tags"
                labelPlacement="end"
              />
            )}
            {/* Toggle switch for Search Terms */}
            {searchTermNames && searchTermNames.length > 0 && (
              <FormControlLabel
                control={<Switch checked={showSearchTerms} onChange={handleToggleSearchTerms} color="secondary" />}
                label="Search Terms"
                labelPlacement="end"
              />
            )}
          </div>
        </Box>




      </div>


    ),
    // Custom rendering for expand button
    enableExpandAll: true, // I don't think this is particularly better functionality but it saves space and I can't edit the column header
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'light'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
    
    //conditionally render detail panel
    renderDetailPanel: ({ row }) => (
      <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: '1fr', // Single column layout
      width: '60%',
      maxWidth: '100%', // Maximum width set to 100% of the container width
      overflowX: 'hidden', // Enable horizontal scrolling if necessary
      justifyContent: 'start', // Justify content to the start (left)
    }}
  >
        {row.original.description && row.getIsExpanded() && (
          <>
            {/* Display description in the first column */}
            <Typography sx={{ mb: 2, pl: 2, overflowX: 'hidden' }}>
    <b>Document Summary:</b>
    {row.original.description}
</Typography>
{/* Display tags in the second column
<Box sx={{ gridColumn: '3 / span 1', mb: 2 }}>
    <Typography variant="subtitle1">
        <b>Additional Info:</b>
    </Typography>
    {row.original.tags && row.original.tags.split(',').map((tag, index) => (
        <Chip key={index} label={tag} variant="outlined" sx={{ mr: 1, mb: 1 }} />
    ))}
    {/* Add additional information here
</Box>*/}
          </>
        )}
      </Box>
    ),




    // Additional options for editing
    editDisplayMode: 'cell',
    enableCellActions: true,
    enableClickToCopy: 'context-menu',
    editVariant: 'select',
    enableEditing: true,
    muiTableBodyCellProps: ({ cell, column, table }) => ({
      onClick: () => {
        table.setEditingCell(cell); //set editing cell
        //optionally, focus the text field
        queueMicrotask(() => {
          const textField = table.refs.editInputRefs.current[column.id];
          if (textField) {
            textField.focus();
            textField.select?.();
          }
        });
      },
    }),
    getRowId: (row) => row.id,

    onEditingRowSave: ({ values, table }) => {
      handleSaveRow(values); // Call handleSaveRow with the updated values
    },



    renderCell: ({ column, value, setValue, row }) => {
      if (column.accessorKey === 'classifierCode') {
        const handleCodeChange = (newValue) => {
          // Update the value in the cell
          setValue(newValue);
          // Call handleEditCell to update the edited row
          handleEditCell(newValue, row.id); // Pass the newValue and row id to handleEditCell
        };

        const classifierId = column.classifierId;
        const classifierData = userStandard.classifiers.find(
          (classifier) => classifier.id === classifierId
        );

        if (classifierData) {
          const handleSelectChange = (e) => {
            const newValue = e.target.value;
            const selectedItem = classifierData.data.find((item) => item.code === newValue);
            const codeParts = newValue.split(',');
            const updatedRow = {
              ...row.original,
              classifierCode: codeParts[0],
              description: selectedItem?.description || '',
            };
            setEditedRow({ ...editedRow, [row.id]: updatedRow });
          };

          return (
            <Select
              value={value}
              onChange={handleSelectChange}
              sx={{ marginLeft: 'auto' }} // Align dropdown to the right
            >
              {classifierData.data.map((item) => (
                <MenuItem key={item.code} value={item.code}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          );
        }
      } else if (column.accessorKey === 'edit') {
        return (
          <Button
            variant="outlined"
            onClick={() => table.setEditingRow(row.id)}
          >
            Edit
          </Button>
        );
      } else if (column.accessorKey === 'checkbox') {
        return <input type="checkbox" />;
      }
      return value;
    },
  });

  return (
    <>

      <MaterialReactTable table={table} />

    </>
  );
};

export default ResultsTable;
